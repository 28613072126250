import { action, computed, makeAutoObservable, observable, reaction } from 'mobx';
import { differenceInDays } from 'date-fns';
import {
    ACTIVITIES_UNREGULATED,
    ACTIVITIES_2G,
    ACTIVITIES_2G_PLUS,
    ACTIVITIES_3G,
    ActivityType,
    DurationNames,
    DurationName,
    State,
    DurationToDays,
} from './types';
import statesData from './statesData';
import { objectEntries } from './ts-utils';

export default class VaccinationCalculatorStore {

    @observable
    public activity: ActivityType | null = null;

    @observable
    public state: State | null = null;

    @observable
    public vaccinationCount = 0;

    @observable
    public age = 0;

    @observable
    public lastVaccinationDuration: DurationName | null = null;

    constructor() {
        makeAutoObservable(this);
        // this.loadState();
    }

    @action
    public loadState() {
        if (typeof window !== 'undefined') {
            const savedState = window.localStorage.getItem('saved-state');
            if (savedState) {
                Object.assign(this, JSON.parse(savedState));
            }
            reaction(
                () => ({
                    activity: this.activity,
                    state: this.state,
                    age: this.age,
                    vaccinationCount: this.vaccinationCount,
                    lastVaccinationDuration: this.lastVaccinationDuration,
                }),
                (data) => {
                    window.localStorage.setItem('saved-state', JSON.stringify(data));
                }
            );
        }
    }

    @action
    public setActivity(activity: ActivityType | null) {
        this.activity = activity;
    }

    @computed
    public get isUnregulatedActivity() {
        return ACTIVITIES_UNREGULATED.includes(this.activity as any);
    }

    @computed
    public get is3G() {
        return ACTIVITIES_3G.includes(this.activity as any);
    }

    @computed
    public get is2GPlus() {
        return ACTIVITIES_2G_PLUS.includes(this.activity as any);
    }

    @computed
    public get is2G() {
        return ACTIVITIES_2G.includes(this.activity as any);
    }

    @computed
    public get needState() {
        return Boolean(this.activity && !this.isUnregulatedActivity);
    }

    @action
    public setState(state: State | null) {
        this.state = state;
    }

    @computed
    public get stateData() {
        if (!this.state) {
            return undefined;
        }
        return statesData[this.state];
    }

    @computed
    public get validationRules() {
        if (!this.stateData) {
            return undefined;
        }
        return this.stateData.validationRules.find(x => x.validUntil.getTime() > Date.now());
    }

    @computed
    public get needAge() {
        return Boolean(this.needState && this.state);
    }

    @action
    public setAge(age: number) {
        this.age = age;
        this.vaccinationCount = 0;
        this.lastVaccinationDuration = null;
    }

    public get needVaccinationCount() {
        const minFreeAge = this.validationRules?.rules.find(x => x.belowAge && Object.keys(x).length === 1)?.belowAge;
        return Boolean(this.needAge && this.age && (!minFreeAge || this.age > minFreeAge));
    }

    @action
    public setVaccinationCount(vaccinationCount: number) {
        this.vaccinationCount = vaccinationCount;
    }

    @computed
    public get needLastVaccinationDuration() {
        return Boolean(this.activity && !this.isUnregulatedActivity && this.vaccinationCount === 2);
    }

    @action
    public setLastVaccinationDuration(lastVaccinationDuration: DurationName | null) {
        this.lastVaccinationDuration = lastVaccinationDuration;
    }

    public setDurationByDate(d: Date) {
        const days = differenceInDays(new Date(), d);
        this.lastVaccinationDuration = objectEntries(DurationToDays)
            .find(x => days <= x[1])?.[0] ?? null;
    }

    @computed
    public get hasToTest() {
        if (
            !this.activity ||
            this.needState && !this.state ||
            this.needLastVaccinationDuration && !this.lastVaccinationDuration ||
            this.needAge && !this.age
        ) {
            return undefined;
        }
        if (this.isUnregulatedActivity) {
            return false;
        }
        if ((this.is2GPlus || this.is2G) && this.vaccinationCount === 0) {
            return 'Ohne Impfung / Genesung ist der Eintritt verboten';
        }
        if (!this.validationRules) {
            return 'Fehler: Konnte keine passende Testregelung finden';
        }
        for (const rule of this.validationRules.rules) {
            if (rule.vaccinationCount && this.vaccinationCount < rule.vaccinationCount) {
                continue;
            }
            const durationIndex = DurationNames.indexOf(this.lastVaccinationDuration!);
            if (rule.minDuration && (durationIndex < 0 || durationIndex < rule.minDuration)) {
                continue;
            }
            if (rule.maxDuration && (durationIndex < 0 || durationIndex > rule.maxDuration)) {
                continue;
            }
            if (rule.belowAge && (!this.age || this.age > rule.belowAge)) {
                continue;
            }
            if (rule.activityType) {
                if (
                    rule.activityType === '2G' && !this.is2G ||
                    rule.activityType === '2G+' && !this.is2GPlus ||
                    rule.activityType === '3G' && !this.is3G
                ) {
                    continue;
                }
            }
            return false;
        }
        if (this.is2GPlus && this.vaccinationCount >= 3) {
            return false;
        }
        return true;
    }
}
