import { DurationNames, StateInfo } from '../types';

const data: StateInfo = {
    validationRules: [
        {
            validUntil: new Date('2022-02-14'),
            rules: [
                // Personen mit Auffrischungsimpfung (d.h. dreifach geimpft)
                {
                    vaccinationCount: 3,
                },
                // Personen mit einer zweimaligen Impfung (ab dem 15. Tag nach der zweiten Impfung bis zum 90. Tag nach der Impfung, gilt auch für Johnson & Johnson)
                {
                    vaccinationCount: 2,
                    minDuration: DurationNames.indexOf('leq15') + 1,
                    maxDuration: DurationNames.indexOf('leq90'),
                },
                {
                    vaccinationCount: 2,
                    minDuration: DurationNames.indexOf('leq14') + 1,
                    activityType: '2G',
                },
                // Genesene (ab dem 28. Tag bis zum 90. Tag ab dem Datum der Abnahme des positiven Tests)
                {
                    vaccinationCount: 1,
                    vaccinationType: 'infection',
                    minDuration: DurationNames.indexOf('leq28') + 1,
                    maxDuration: DurationNames.indexOf('leq90'),
                },
                // Geimpfte/genesene Kinder/Jugendliche bis 17 Jahre
                {
                    vaccinationCount: 1,
                    belowAge: 18,
                    vaccinationType: 'infection',
                },
                // Kinder bis 12 Jahre und 3 Monaten gelten als geimpft und benötigen keinen zusätzlichen Testnachweis
                {
                    belowAge: 12 + (3 / 12),
                },
            ]
        }
    ]
};

export default data;
