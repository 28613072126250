import { DurationNames, StateInfo } from '../types';

const data: StateInfo = {
    validationRules: [
        {
            validUntil: new Date('2022-02-14'),
            rules: [
                // Personen mit Auffrischungsimpfung (d.h. dreifach geimpft)
                {
                    vaccinationCount: 3,
                },
                // „Frisch“ doppelt Geimpfte (max. 3 Monate, ab dem Tag der Zweitimpfung)
                {
                    vaccinationCount: 2,
                    maxDuration: DurationNames.indexOf('leq90'),
                },
                // „Frisch“ Genesene (max. 3 Monate, ab dem Tag des positiven PCR-Tests)
                {
                    vaccinationCount: 1,
                    vaccinationType: 'infection',
                    maxDuration: DurationNames.indexOf('leq90'),
                },
                // Geimpfte/genesene Kinder/Jugendliche bis 17 Jahre
                {
                    vaccinationCount: 1,
                    belowAge: 18,
                    vaccinationType: 'infection',
                },
                // Kinder unter 6 Jahren und Kinder, die noch nicht eingeschult sind: keine Nachweis-/Maskenpflicht
                {
                    belowAge: 6,
                },
            ]
        }
    ]
};

export default data;
