import React from 'react';
import { PageProps } from 'gatsby';
import { observer } from 'mobx-react';
import { AlertTitle, Box, Button, Dialog, FormControl, InputLabel, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { StaticDatePicker } from '@mui/lab';
import { Alert } from '@mui/material';

import { ACTIVITIES, STATES } from '../data/types';
import VaccinationCalculatorStore from '../data/VaccinationCalculatorStore';

const IndexRoute = observer((props: PageProps) => {
    const store = React.useState(() => new VaccinationCalculatorStore())[0];
    React.useEffect(() => {
        store.loadState();
        // setTimeout(() => store.loadState(), 6000);
    }, []);

    const [datePickerOpen, setDatePickerOpen] = React.useState(false);
    const [pickerDate, setPickerDate] = React.useState<Date | null>(new Date());

    return (
        <Box m='auto' px={{ xs: 2, sm: 3 }} pt={2} pb={6} maxWidth={800}>
            <Stack direction='column' spacing={4}>

                <Paper sx={{ p: 2 }}>
                    <Typography pb={2}>Was möchtest du tun?</Typography>

                    <FormControl key={store.activity ? 1 : 2} fullWidth variant='outlined'>
                        <InputLabel>Aktivität</InputLabel>
                        <Select
                            label='Aktivität'
                            value={store.activity || ''}
                            onChange={e => store.setActivity(!e.target.value ? null : e.target.value as any)}
                        >
                            {ACTIVITIES.map(activity => (
                                <MenuItem key={activity} value={activity}>{activity}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Paper>

                {store.needState && (
                    <Paper sx={{ p: 2 }}>
                        <Typography variant='body1' pb={2}>Um welches Bundesland geht es?</Typography>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel>Bundesland</InputLabel>
                            <Select
                                label='Bundesland'
                                value={store.state}
                                onChange={e => store.setState(!e.target.value ? null : e.target.value as any)}
                            >
                                {STATES.map(state => (
                                    <MenuItem key={state} value={state}>{state}</MenuItem>
                                ))}
                            </Select>
                            {/* {store.validationRules && (
                                <FormHelperText>
                                    Aktuelle Regelung gültig bis {store.validationRules.validUntil.toLocaleDateString()}
                                </FormHelperText>
                            )} */}
                        </FormControl>
                        {store.validationRules && (
                            <Typography variant='body2' pt={1}>
                                Aktuelle Regelung gültig bis {store.validationRules.validUntil.toLocaleDateString()}
                            </Typography>
                        )}
                    </Paper>
                )}

                {store.needAge && (
                    <Paper sx={{ p: 2 }}>
                        <Typography variant='body1' pb={2}>Wie alt bist du?</Typography>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel>Alter</InputLabel>
                            <Select
                                label='Alter'
                                value={store.age}
                                onChange={e => store.setAge(e.target.value as number)}
                            >
                                <MenuItem value={12 + (3 / 12)}>Unter 12 Jahre und 3 Monate</MenuItem>
                                <MenuItem value={18}>Unter 18</MenuItem>
                                <MenuItem value={200}>Über 18</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                )}

                {store.needVaccinationCount && (
                    <Paper sx={{ p: 2 }}>
                        <Typography variant='body1' pb={2}>Wie oft wurdest du geimpft oder genesen?</Typography>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel>Anzahl Impfungen / Genesungen</InputLabel>
                            <Select
                                label='Anzahl Impfungen / Genesungen'
                                value={store.vaccinationCount}
                                onChange={e => store.setVaccinationCount(e.target.value as number)}
                            >
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3+</MenuItem>
                            </Select>
                        </FormControl>
                    </Paper>
                )}

                {store.needLastVaccinationDuration && (
                    <Paper sx={{ p: 2 }}>
                        <Typography variant='body1' pb={2}>Wann oder wie lange ist deine letzte Impfung her?</Typography>
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems={{ xs: 'stretch', md: 'center' }}>

                            <FormControl sx={{ flex: 1 }}>
                                <InputLabel>Dauer</InputLabel>
                                <Select
                                    variant='outlined'
                                    label='Dauer'
                                    value={store.lastVaccinationDuration}
                                    onChange={e => store.setLastVaccinationDuration(e.target.value as any)}
                                >
                                    <MenuItem value={'leq14'}>Bis zu 14 Tage</MenuItem>
                                    <MenuItem value={'leq15'}>Bis zu 15 Tage</MenuItem>
                                    <MenuItem value={'leq28'}>Bis zu 28 Tage</MenuItem>
                                    <MenuItem value={'leq90'}>Bis zu 90 Tage (3 Monate)</MenuItem>
                                    <MenuItem value={'gt90'}>Über 3 Monate</MenuItem>
                                </Select>
                            </FormControl>

                            <Stack direction='row' spacing={2} alignItems='center'>
                                <Typography>oder</Typography>
                                <Button variant='contained' onClick={() => setDatePickerOpen(true)}>
                                    Datum auswählen
                                </Button>
                            </Stack>

                            <Dialog open={datePickerOpen} onClose={() => setDatePickerOpen(false)} keepMounted={false}>
                                <StaticDatePicker
                                    displayStaticWrapperAs='desktop'
                                    views={['year', 'month', 'day']}
                                    openTo='month'
                                    value={pickerDate}
                                    minDate={new Date(2019, 1, 1)}
                                    maxDate={new Date()}
                                    defaultCalendarMonth={new Date()}
                                    onAccept={d => {
                                        setPickerDate(!d ? null : new Date(d.getTime() + 10));
                                        setDatePickerOpen(false);
                                        if (d) {
                                            store.setDurationByDate(d);
                                        }
                                    }}
                                    onChange={d => setPickerDate(d)}
                                    renderInput={() => <></>}
                                />
                            </Dialog>
                        </Stack>
                    </Paper>
                )}

                {store.hasToTest !== undefined && (
                    <div>
                        <Alert
                            variant='filled'
                            severity={typeof store.hasToTest === 'string' ? 'error' : store.hasToTest ? 'warning' : 'success'}
                        >
                            <AlertTitle>Muss ich mich testen?</AlertTitle>
                            {/* <Typography variant='h5'>{store.hasValidationError ? 'Angaben ungültig' : store.hasToTest ? 'JA!' : 'Nein :-)'}</Typography> */}
                            <Typography variant='h5'>{typeof store.hasToTest === 'string' ? store.hasToTest : store.hasToTest ? 'JA!' : 'Nein :-)'}</Typography>
                        </Alert>
                    </div>
                )}

                {/* <Typography whiteSpace='pre'>
                    {JSON.stringify(store.validationRules, undefined, 4)}
                </Typography> */}

            </Stack>
        </Box>
    );
});
export default IndexRoute;
