

export type ArrayType<T extends readonly any[]> = T extends readonly (infer U)[] ? U : never;

export type VaccinationType = 'other' | 'infection' | 'moderna' | 'astra' | 'pfizer';

export const STATES = [
    'Rheinland-Pfalz',
    'Hessen',
] as const;

export type State = ArrayType<typeof STATES>;

export const ACTIVITIES_UNREGULATED = [
    'Lebensmittel- und Getränkemärkte',
    'Apotheken, Drogerien, Optiker, Tierbedarf und ähnliches',
    'Buchhandlungen und ähnliche',
    'Tankstellen',
    'Großhandel',
] as const;

export const ACTIVITIES_3G = [
    '3G',
    'Hotels und Beherbergungsbetriebe',
] as const;

export const ACTIVITIES_2G = [
    '2G',
    'Außengastronomie',
    'Veranstaltung bis 1000 Personen',
] as const;

export const ACTIVITIES_2G_PLUS = [
    '2G-Plus',
    'Innengastronomie / Restaurants',
    'Veranstaltungen in geschlossenen Räumen',
    'Sport im Innenbereich',
    'Schwimmbäder und Thermen',
    'Kino, Theater, Konzerthäuser und ähnliche Kultureinrichtungen',
] as const;

export const ACTIVITIES = [
    ...ACTIVITIES_2G_PLUS,
    ...ACTIVITIES_2G,
    ...ACTIVITIES_3G,
    ...ACTIVITIES_UNREGULATED,
] as const;
(ACTIVITIES as any as string[]).sort();

export type ActivityType = ArrayType<typeof ACTIVITIES>;

export const DurationNames = [
    'leq14',
    'leq15',
    'leq28',
    'leq90',
    'gt90',
] as const;

export type DurationName = ArrayType<typeof DurationNames>;

export const DurationToDays: Record<DurationName, number> = {
    'leq14': 14,
    'leq15': 15,
    'leq28': 28,
    'leq90': 90,
    'gt90': Number.MAX_SAFE_INTEGER,
};

export interface StateInfo {
    validationRules: {
        validUntil: Date;
        rules: ValidationEntry[];
    }[];
}

export interface ValidationEntry {
    vaccinationCount?: number;
    /** inclusive minimal duration */
    minDuration?: number;
    /** inclusive maximal duration */
    maxDuration?: number;
    belowAge?: number;
    vaccinationType?: VaccinationType;
    activityType?: '3G' | '2G' | '2G+';
}
