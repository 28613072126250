import { State, StateInfo } from '../types';

import rlp from './rlp';
import hessen from './hessen';

const statesData: Record<State, StateInfo> = {
    'Rheinland-Pfalz': rlp,
    'Hessen': hessen,
};

export default statesData;
