// @description see default export
// @authors <remove if not necessary>

/**
 * Returns all keys in the object where key extends the KeyCondition type and the value in the object extends the ValueCondition type.
 * 
 * @example
 * type _T1 = FilterKeys<{ a: number; b: string; c: string[]; }, string>; // = 'b'
 * type _T2 = FilterKeys<{ a: number; b: string; c: string[]; }, Array<any>>; // = 'c'
 * type _T3 = FilterKeys<{ a: number; [mySymbol]: number; }, number, string>; // = 'a'
 */
export type FilterKeys<O, ValueCondition, KeyCondition = keyof any> = {
    [Key in keyof O]: Key extends KeyCondition ? O[Key] extends ValueCondition ? Key : never : never
}[keyof O];

export type FilterObject<O, Condition> = Pick<O, FilterKeys<O, Condition>>;

export type ArrayType<T extends Array<any>> = T extends Array<infer R> ? R : never;

export type PromiseResult<T extends Promise<any>> = T extends Promise<infer R> ? R : never;

export type Writeable<T extends {}> = {
    -readonly [P in keyof T]: T[P];
};

export type PartialRecord<K extends keyof any, T> = { [P in K]?: T; };

/** Object.entries with improved typings only */
export const objectEntries: <T>(obj: T) => [keyof T, T[keyof T]][] = Object.entries;

/** Object.fromEntries with improved typings only */
export const objectFromEntries: <K extends keyof any, T>(entries: Iterable<readonly [K, T]>) => Partial<Record<K, T>> = Object.fromEntries;

/** Object.keys with improved typings only */
export const objectKeys: <T>(obj: T) => (keyof T)[] = Object.keys;

/** Object.values with improved typings only */
export const objectValues: <T>(obj: T) => (T[keyof T])[] = Object.values;

/** class decorator to force static fields to be implemented */
export function StaticImplements<T>() {
    return (_constructor: T) => { /* nop */ };
}
